// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akce-js": () => import("./../../../src/pages/akce.js" /* webpackChunkName: "component---src-pages-akce-js" */),
  "component---src-pages-cenik-js": () => import("./../../../src/pages/cenik.js" /* webpackChunkName: "component---src-pages-cenik-js" */),
  "component---src-pages-diagnostika-tcm-js": () => import("./../../../src/pages/diagnostika-tcm.js" /* webpackChunkName: "component---src-pages-diagnostika-tcm-js" */),
  "component---src-pages-galerie-js": () => import("./../../../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-konzultace-js": () => import("./../../../src/pages/konzultace.js" /* webpackChunkName: "component---src-pages-konzultace-js" */),
  "component---src-pages-magazin-js": () => import("./../../../src/pages/magazin.js" /* webpackChunkName: "component---src-pages-magazin-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-obchodni-podminky-js": () => import("./../../../src/pages/obchodni-podminky.js" /* webpackChunkName: "component---src-pages-obchodni-podminky-js" */),
  "component---src-pages-zasady-ochrany-osobnich-udaju-js": () => import("./../../../src/pages/zasady-ochrany-osobnich-udaju.js" /* webpackChunkName: "component---src-pages-zasady-ochrany-osobnich-udaju-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

